<template>
  <div id="Painel" class="container-fundo" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

        <v-toolbar-title class="text-white title-page mt-3 ml-4 w-100"
          >Painel de Controle
        </v-toolbar-title>

        <v-toolbar-title
          class="mt-0 mb-15 ml-4 pa-0 d-flex flex-column w-100"
          dark
          elevation="0"
        >
          <div class="d-flex align-center w-100" tabindex="-1">
          </div>
        </v-toolbar-title>

      </v-toolbar-title>

    <!-- Container Painel -->
    <v-container class="container-main pt-0 px-0 mt-n11">
      <v-card class="card d-flex flex-column" elevation="1">
        <v-card-text class="pt-4 px-4 mb-7">
          <!-- Container Cards -->
          <v-row class="row d-flex flex-row">
            <v-col v-for="(item, i) in paineis" :key="i" sm="12" md="6" lg="4">
              <v-card class="card-panel" elevation="1">
                <router-link
                  :to="item.componente"
                  exact
                  class="nav-link font-weight-regular no-underline"
                  aria-current="page"
                >
                  <div class="d-flex flex-no-wrap justify-space-between mr-4 h-100">
                    <div class="d-flex flex-column justify-space-between h-100">
                      <div>
                      <v-card-title
                        class="subtitle-1 font-weight-bold"
                        v-text="item.titulo"
                      ></v-card-title>

                      <v-card-subtitle
                        class="caption"
                        v-text="item.descricao"
                      ></v-card-subtitle>
                      </div>

                      <v-card-actions>
                        <v-btn
                          class="ml-0 mt-0"
                          fab
                          icon
                          height="40px"
                          right
                          width="40px"
                        >
                          <v-icon class="btn-next"
                            >mdi-arrow-right-circle-outline</v-icon
                          >
                        </v-btn>
                      </v-card-actions>
                    </div>

                    <v-avatar class="width-fixed mt-4" size="90" tile>
                      <v-icon class="identification"> {{ item.src }}</v-icon>
                    </v-avatar>
                  </div>
                </router-link>
              </v-card>
            </v-col>
          </v-row>
          <!-- Fim Container Cards -->
        </v-card-text>
      </v-card>
    </v-container>
    <!-- Fim Container Painel -->

  </div>
</template>

<script>
import store_usuario from "../store/store_usuario";
import { COR_PRINCIPAL, COR_SECUNDARIA } from "../services/constantes";

export default {
  name: "Painel",
  components: {},

  data() {
    return {
      store_usuario   : store_usuario,
      COR_PRINCIPAL   : COR_PRINCIPAL,
      COR_SECUNDARIA  : COR_SECUNDARIA,

      paineis: [
        {
          cod_funcao: 1,
          visivel: false,
          src: "mdi-account-group-outline",
          titulo: "Cliente",
          descricao:
            "Cliente Final: Usuários do site, proponentes, inquilinos, etc",
          componente: "painelcliente",
        },
        {
          cod_funcao: 2,
          visivel: false,
          src: "mdi-face-agent",
          titulo: "Usuário",
          descricao:
            "Usuários do Sistema: Atendentes da empresa ou uduários do sistema que casdastram novos Leads ou fazem a venda interna",
          componente: "painelusuario",
        },
        {
          cod_funcao: 3,
          visivel: false,
          src: "mdi-handshake-outline",
          titulo: "Corretor",
          descricao:
            "Corretores: São parceiros de venda como corretores da Empresa ou Parceiros",
          componente: "painelcorretor",
        },
        {
          cod_funcao: 4,
          visivel: false,
          src: "mdi-briefcase-variant-outline",
          titulo: "Gestor",
          descricao:
            "Gestor: Gerente, Supervisor, Lider de Equipe. Pessoas que gerenciam equipes de venda e possuem privilégios de aprovações de Fases ou Etapas",
          componente: "painelgestor",
        },
        {
          cod_funcao: 5,
          visivel: false,
          src: "mdi-abacus",
          titulo: "Administrador",
          descricao:
            "Administrador: Diretor de Empresa, Supervisor. Se trata de um usuário com os privilégios mais avançados do sistema",
          componente: "paineladministrador",
        },
        // {
        //   cod_funcao: 6,
        //   visivel: false,
        //   src: "mdi-rocket-launch-outline",
        //   titulo: "Lançamentos",
        //   descricao: "Ellie Goulding",
        //   componente: "painellancamentos"
        // },
        {
          cod_funcao: 7,
          visivel: false,
          src: "mdi-home-city-outline",
          titulo: "Proprietário",
          descricao:
            "Proprietário: Pessoas que possuem imóveis ou empreendimentos e acessam dados estatísticos de suas propriedades",
          componente: "painelproprietario",
        },
      ],
    };
  },

  created() {
    var la_paineis = [];
    this.paineis.map((painel) => {
      var li_idx = 0;
      // Apagando elementos que não pertencem ao usuário
      if (store_usuario.user && 
          store_usuario.user.pessoa_funcao && 
          store_usuario.user.pessoa_funcao.find((value) => value.cod_funcao === painel.cod_funcao)
         ){
        la_paineis.push(painel);
        //this.paineis.splice(li_idx, 1);
        //console.log('RETIRAR : ', li_idx);
        //li_idx++;
      }
    });
    this.paineis = la_paineis;
    //console.log("CREATED() ", store_usuario.user.pessoa_funcao, this.paineis);
  },

  destroyed() {
    // console.log("DESTROYED() ");
  },

  methods: {
    abre(cod_funcao) {
      var li_resp = this.paineis.find(
        (value) => value.cod_funcao === cod_funcao
      );
      if (li_resp) {
        // console.log("ABRIU() ", li_resp.componente);
        this.$router.push(li_resp.componente);
      }
    },

    changeColor(variable, color) {
      document.documentElement.style.setProperty(variable, color);
    }

  },

};
</script>

<style scoped>
  /* >>> {
    --accent-color: #c1d32f;
  } */

  .container {
    padding-bottom: 0!important;
  }
  .main-title {
    background: #0548a3;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .title-page {
    max-width: 600px !important;
    opacity: 0.87;
  }

  .container-main {
    max-width: 100%;
  }

  @media (max-width: 599px) {
    .container-main {
      max-width: 100% !important;
    }
  }

  .card {
    border-radius: 8px;
    background: var(--COR_SECUNDARIA) !important;
    min-height: calc(100vh - 116px) !important;
  }

  .h-100 {
    height: 100%;
  }

  .no-underline {
    text-decoration: none;
  }

  .card-panel {
    min-width: 300px;
    height: 100%;
  }

  .identification {
    margin-top: -34px;
    margin-right: -34px;
    font-size: 60px !important;
    color: #274abb;
    opacity: 0.4;
  }

  .btn-next {
    opacity: 0.7;
    font-size: 24px !important;
  }

  .container-fundo {
    height: calc(100vh - 7vh) !important;
  }

</style>